(function ($) {
  popupOfferV1 = {
    formatter: $('.popup-offer-v1')
  };

  Drupal.behaviors.popupOfferV1 = {
    get: function (key) {
      return popupOfferV1[key];
    },

    set: function (key, val) {
      popupOfferV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);
        var offerCode = thisFormatter.children().attr('offer-code') || 1;

        // Hide thank you and signed up blocks
        thisFormatter.find('.outer-wrap.thankyou').hide();
        thisFormatter.find('.outer-wrap.signedup').hide();

        // Hide popup offer on Popup Offer Terms & Conditions page
        if ($('body').hasClass('section-popup-offer-terms')) {
          thisFormatter.find('.outer-wrap.signup').hide();
        }

        // create close button
        thisFormatter.find('.outer-wrap').once().prepend('<div title="Hide" class="popup-offer-close" />');
        thisFormatter.find('.popup-offer-close').once().on('click', function() {
          thisFormatter.hide();
        });

        // control whether or not to show popup (set in the CMS)
        // the node to control this is 17892
        var popupOfferStatus = thisFormatter.find('.showPopupOffer').attr('data-attr');

        if (popupOfferStatus == 'on') {
        // check session cookie
          var cookie = $.cookie('hide_popup_offer');

          /* - create session cookie -
         * this was moved here to overwrite an old cookies
         * that were made to expire after one year
         * this cookie is now a session cookie
        */
          $.cookie('hide_popup_offer', offerCode, { path: '/' });

          // if cookie does not exist
          if (cookie != offerCode) {
          // offer code is an optional field
            if (offerCode.length > 0) {
            // check offer code timing status
              generic.jsonrpc.fetch({
                method: 'offer',
                params: [offerCode],
                onSuccess: function (r) {
                  var offer = r.getValue();
                  var isActive = offer && (offer.timing === 'active');

                  if (isActive) {
                  // if active, show
                    thisFormatter.delay(1600).fadeIn(); // empty fadeIn() animation speed defaults to 400, was previosuly set to 1200.
                  }
                },
                onFailure: function (r) {
                // started pg server?
                }
              });
            } else {
            // if no cookie and no offer code, show
              thisFormatter.delay(1600).fadeIn(1200);
            } // end offer code check
          } // end cookie check          
        }

        // Submit form
        var form = thisFormatter.find('.signup-forms .popover-newsletter-signup #popover-newsletter-signup');

        form.find('.form-submit.button').on('click', function(e) {
          e.preventDefault();

          // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces        
          var fields = [
            'PC_EMAIL_ADDRESS',
            'PC_EMAIL_PROMOTIONS',
            'LAST_SOURCE',
            'LANGUAGE_ID',
            'COUNTRY_ID',
            'LAST_SOURCE',
            'ORIGINAL_SOURCE'
          ];
          var paramObj = {};

          paramObj['_SUBMIT'] = 'email_signup';

          // loop through all the fields and get the values
          $.each(fields, function (index, value) {
            var formField = $('#popover-newsletter-signup input[name=' + value + ']');

            paramObj[value] = formField.val();
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              // was the user previously opted into email promotions before this submission?
              // this is a 0/1 value
              var previously_opted_in_email_promotion = response.userinfo.previously_opted_in_email_promotion;
              // are we still running special offer codes which are emailed out? 
              // this is a 0/1 value
              var special_offer_code_status = response.userinfo.special_offer_code_status;

              // user was not previously opted and we are running special offer emails
              if (!previously_opted_in_email_promotion && special_offer_code_status) {
                thisFormatter.find('.outer-wrap.signedup').hide();
              }

              // show normal success message since user was already signed up
              else {
                $('body').addClass('popup-offer');
                if ($('body.popup-offer').hasClass('pg')) {
                  $('body.pg').addClass('popup-signedup');
                // thisFormatter.find('.outer-wrap.signedup').removeClass('hidden');
       	      }
                thisFormatter.find('.outer-wrap.signedup').show();
              }

              thisFormatter.find('.outer-wrap.signup').hide();
              thisFormatter.find('.outer-wrap.thankyou').show();
            },
            onFailure: function (jsonRpcResponse) {
              var messages = jsonRpcResponse.getMessages();

              $('.error').empty().html(messages[0].text).removeClass('hidden');
            }
          });
        });
        // Manual popup offer
        $(window).on('show_popup_offer', function () {
        // show popup code
          thisFormatter.delay(1600).fadeIn(1200);
        });
      });
    }
  };
})(jQuery);
